import { useQuery } from "react-query";
import { DocService } from "../../../services/dataService";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import ArticleView from "../dashboard/components/articleView";

const ArticleDetails = () => {
  const { articleId } = useParams();
  const article = useQuery(["articleDetail", articleId], () => {
    return DocService.get(articleId);
  });
  return (
    <div className="h-full overflow-y-auto">
      {article.isLoading && (
        <div className="h-full flex justify-center items-center">
          <Spin />
        </div>
      )}
      {article.data && (
        <div className="text-base text-neutral-600">
          <ArticleView article={article.data} fullView={true} />
        </div>
      )}
    </div>
  );
};

export default ArticleDetails;
