import { message, Popover } from "antd";
import {
  Bookmark01Icon,
  BookmarkCheck02Icon,
  Calendar03Icon,
  DnaIcon,
  Medicine02Icon,
  UserMultiple02Icon,
} from "../../../components/icons";
import PDFDocView from "../../../../pages/pubmedData/DocView";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BookmarkService } from "../../../../services/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";


const SmallButton = ({ children, onClick, disabled }) => (
  <button
    className={`text-xs px-2 py-1 rounded-md bg-neutral-100 text-neutral-700 transition hover:bg-neutral-200 active:bg-neutral-300 ${
      disabled ? "opacity-50 cursor-not-allowed" : " cursor-pointer"
    }`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

const BookmarkButton = ({ articleId }) => {
  const queryClient = useQueryClient();
  const bookmarkList = useQuery(
    ["bookmarks", "mine"],
    () => BookmarkService.getAll({ mine: true }),
    {
      staleTime: Infinity,
    }
  );

  const bookmarks = useQuery(
    ["bookmarks", articleId],
    () => BookmarkService.checkItem(articleId),
    {
      staleTime: Infinity,
    }
  );

  const bookMarkMapping = {};
  bookmarkList.data?.results?.forEach((bookmark) => {
    bookMarkMapping[bookmark.id] = bookmark;
  });

  const addBookmarkItem = useMutation((data) => BookmarkService.addItem(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(["bookmarks", articleId]);
      message.success("Added to bookmark");
    },
  });

  const removeBookmarkItem = useMutation((id) => BookmarkService.deleteItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["bookmarks", articleId]);
      message.success("Removed from bookmark");
    },
  });

  const toggleBookmark = (bookmarkId) => {
    let bookmark = bookmarks.data?.results?.find((b) => b.id === bookmarkId);
    if (bookmark) {
      removeBookmarkItem.mutate(bookmark.item_id);
    } else {
      addBookmarkItem.mutate({
        item_id: articleId,
        bookmark_id: bookmarkId,
      });
    }
  };

  return (
    <Popover
      title="Bookmark"
      trigger="click"
      content={
        <div>
          {bookmarkList.data?.results?.map((bookmark) => (
            <div
              key={bookmark.id}
              onClick={(e) => {
                e.stopPropagation();
                toggleBookmark(bookmark.id);
              }}
              className="cursor-pointer flex items-center gap-1"
            >
              <div className="flex items-center gap-1 text-sm p-2 hover:bg-neutral-100 rounded-md w-full text-neutral-600">
                {bookmarks.data?.results?.find((b) => b.id === bookmark.id) ? (
                  <BookmarkCheck02Icon className="w-4 h-4 text-neutral-500" />
                ) : null}
                {bookmark.name}
              </div>
            </div>
          ))}
        </div>
      }
    >
      <SmallButton>
        <Bookmark01Icon className="w-4 h-4" />
      </SmallButton>
    </Popover>
  );
};

const ArticleCard = ({ article }) => {
  const [pdfFlag, setPdfFlag] = useState(false);

  const navigate = useNavigate();
  const onOpen = () => {
    navigate(`/v2/article/${article.id}`);
  };
  return (
    <>
      {pdfFlag && (
        <PDFDocView
          docId={article.pubmed_id}
          pmid={article.pubmed_id}
          visible={pdfFlag}
          onClose={() => setPdfFlag(false)}
        />
      )}
      <div className="border p-4 rounded-md flex flex-col gap-3">
        <div className="flex gap-2 flex-wrap">
          {article.extraction.relevance? (
            <span className="text-xs text-green-500 bg-green-50 border border-green-300 px-2 py-0.5 rounded-lg">
              Include
            </span>
          ) : (
            <span className="text-xs text-red-500 bg-red-50 border border-red-300 px-2 py-0.5 rounded-lg">
              Exclude
            </span>
          )}
          {article.nccn_tissue_type && (
            <span className="text-xs text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <DnaIcon className="w-4 h-4" />
              {article.nccn_tissue_type}
            </span>
          )}
          {article.drug && (
            <span className="text-xs text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <Medicine02Icon className="w-4 h-4" />
              {article.drug}
            </span>
          )}
          {article.published_year && (
            <span className="text-xs text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <Calendar03Icon className="w-4 h-4" />
              {article.published_year}
            </span>
          )}
        </div>
        <div>
          <h3 className="text-sm text-neutral-700 font-semibold">
            {article.title}
          </h3>
        </div>
        {article.authors?.length > 0 && (
          <div className="text-xs text-neutral-600  italic">
            {article.authors?.length > 0 && (
              <UserMultiple02Icon className="w-4 h-4 mr-1 inline-block" />
            )}
            {article.authors?.slice(0, 5).join(", ")}
          </div>
        )}

        <div className="flex gap-2 ">
          {article.pubmed_id && (
            <SmallButton
              onClick={() => {
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${article.pubmed_id}/`,
                  "_blank"
                );
              }}
            >
              PubMed
            </SmallButton>
          )}
          {article.doi && (
            <SmallButton
              onClick={() => {
                try {
                  // Check if the doi is a valid url
                  if (article.doi.startsWith("http")) {
                    window.open(article.doi, "_blank");
                  } else {
                    window.open(`https://doi.org/${article.doi}`, "_blank");
                  }
                } catch (e) {
                  console.error("Error opening doi link", e);
                  message.error("Error opening doi link");
                }
              }}
            >
              Open DOI
            </SmallButton>
          )}
          <SmallButton
            disabled={!article.full_text_url}
            onClick={() => window.open(article.full_text_url, "_blank")}
          >
            Full Text
          </SmallButton>
          <SmallButton
            onClick={() => {
              setPdfFlag(true);
            }}
            disabled={!article.has_pdf}
          >
            View PDF
          </SmallButton>
          <SmallButton onClick={onOpen}>Open</SmallButton>
          <BookmarkButton articleId={article.id} />
        </div>
      </div>
    </>
  );
};

export default ArticleCard;
