import { useState } from "react";
import { useMatch } from "react-router-dom";
import { ValidationTaskV2 } from "../../../services/dataService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Form, Input, message, Modal, Pagination, Radio, Select } from "antd";
import { Medicine02Icon } from "../../components/icons";
import { Button } from "../../components/button";
import { useAuth } from "../../../authContext";
import "./index.css";
import { ExtractedDetails } from "../dashboard/components/articleView";

const STUDY_TYPE_OPTIONS = [
  { label: "Preclinical", value: "Preclinical" },
  { label: "Case Study", value: "Case Study" },
  { label: "Clinical Trial: non-RCT", value: "Clinical Trial: non-RCT" },
  { label: "Clinical Trial: RCT", value: "Clinical Trial: RCT" },
  { label: "Observational study", value: "Observational study" },
  { label: "Meta-analysis", value: "Review: meta-analysis" },
  { label: "Review", value: "Review: other" },
];

const THERAPEUTIC_ASSOCIATION_OPTIONS = [
  { label: "Positive", value: "Positive" },
  { label: "Negative", value: "Negative" },
  { label: "No Benefit", value: "No Benefit" },
  { label: "Inconclusive", value: "Inconclusive" },
  { label: "Not mentioned", value: "Not mentioned" },
];

const COMBINATION_OPTIONS = [
  { label: "Original", value: "Original" },
  { label: "Combination", value: "Combination" },
];

const TISSUE_STAGE_OPTIONS = [
  { label: "Early", value: "Early" },
  { label: "Advanced", value: "Advanced" },
  { label: "N/A", value: "N/A" },
];

const ValidationModal = ({ id, task, getNext, onClose }) => {
  const { user } = useAuth();
  const { article } = task;
  const queryClient = useQueryClient();
  const valTask = useQuery(["validation_task", id], () =>
    ValidationTaskV2.get(id).then((res) => res)
  );

  const submitMutation = useMutation((data) => {
    return ValidationTaskV2.saveAnnotation(id, data);
  }, {});

  const deleteAnnotation = useMutation(
    (id) => {
      return ValidationTaskV2.deleteAnnotation(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["validation_tasks"]);
      },
    }
  );

  const handleSubmit = async (values) => {
    await submitMutation.mutateAsync({
      ...values,
      pubmed_val_meta_id: id,
      validator: user.username,
      pubmed_id: article.pubmed_id,
      drug: article.drug,
    });

    queryClient.invalidateQueries(["validation_tasks"]);

    message.success("Validation saved successfully");
  };

  const handleSubmitWithNext = async (values) => {
    let res = await submitMutation.mutateAsync({
      ...values,
      pubmed_val_meta_id: id,
      validator: user.username,

      pubmed_id: article.pubmed_id,
      drug: article.drug,
    });
    queryClient.invalidateQueries(["validation_tasks"]);
    message.success("Validation saved successfully");
    getNext();
  };

  const [form] = Form.useForm();
  const annotation = valTask.data?.annotation ? valTask.data.annotation : null;
  const initialValues = annotation
    ? annotation
    : {
        queried_drug: article.drug,
        relevance: article.extraction?.relevance ? "Include" : "Exclude",
        study_type: article.extraction?.study_type || null,
        endpoints_correct: true,
        endpoints_explanation: "",
        drugs_studied_correct: true,
        drugs_studied_explanation: "",
        tissue_type: article.tissue_type || null,
        tissue_stage: "",
        is_queried_drug_studied: null,
        cancer_types_correct: null,
        queried_drug_evaluated:
          article.extraction?.is_queried_drug_evaluated || false,
        n_patients: article.extraction?.num_patients || "N/A",
        published_date: article.published_date || "N/A",
        therapeutic_asoociation: article.extraction?.ta || "",
        is_adhoc: article.extraction?.is_adhoc || false,
        combination: article.extraction?.combination || "",
        is_difficult_to_validate: false,
        difficult_to_validate_explanation: "",
        study_population: "",
        outcome: "",
        serious_adverse_event: null,
        overall_opinion: "",
        reason_for_opinion: "",
      };

  return (
    <Modal
      title="Validate Article"
      open={id ? true : false}
      onCancel={() => {
        onClose();
      }}
      onOk={() => form.submit()}
      width={1400}
      destroyOnClose={true}
      footer={null}
    >
      <div className="flex gap-6 h-[80vh]">
        <div className="w-1/2 overflow-y-auto">
          <div className="text-sm font-semibold mt-4">Title</div>
          <div className="text-sm">{article.title}</div>

          <div className="text-sm font-semibold  mt-4 ">Abstract</div>
          <div className="text-sm ">{article.abstract}</div>

          <div className="text-sm font-semibold mt-8 text-blue-500">
            Extracted Details
          </div>
          <ExtractedDetails article={article} />
        </div>
        <div className="w-1/2 overflow-y-auto">
          <Form
            layout="vertical"
            form={form}
            name="register"
            onFinish={handleSubmit}
            // initialValues={props.existingLabels}
            initialValues={{ ...initialValues }}
            style={{ maxWidth: 600 }}
            scrollToFirstError
          >
            <div className="flex gap-4 justify-between">
              <Form.Item name="queried_drug" label="Queried Drug">
                <Input />
              </Form.Item>
              <Form.Item
                name="queried_drug_evaluated"
                label="Queried Drug Evaluated"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item name="relevance" label="Relevance">
                <Radio.Group optionType="button">
                  <Radio value="Include">Include</Radio>
                  <Radio value="Exclude">Exclude</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="flex gap-4 justify-between">
              <Form.Item
                name="published_date"
                label="Published Date"
                // rules={[{ required: true, message: "Published Date!" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="study_type"
                label="Study Type"
                rules={[{ required: true, message: "Please add Study Type!" }]}
              >
                <Select options={STUDY_TYPE_OPTIONS} />
              </Form.Item>
              <Form.Item name="is_adhoc" label="Is Adhoc/Post-hoc">
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="flex gap-4 ">
              <Form.Item
                name="endpoints_correct"
                label="Endpoints Correct"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="endpoints_explanation"
                label="Endpoints Explanation"
                className="basis-2/3"
              >
                <Input.TextArea />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="drugs_studied_correct"
                label="Drugs Studied Correct"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="drugs_studied_explanation"
                label="Drugs Studied Explanation"
                className="basis-2/3"
              >
                <Input.TextArea />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="tissue_type"
                label="Tissue Type"
                className="basis-1/3"
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="tissue_stage"
                label="Tissue Stage"
                className="basis-1/3"
              >
                {/* <Radio.Group optionType="button">
                  {TISSUE_STAGE_OPTIONS.map((option) => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))} */}
                <Select options={TISSUE_STAGE_OPTIONS} />
              </Form.Item>
              <Form.Item
                name="cancer_types_correct"
                label="Cancer Types Correct"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="is_queried_drug_randomized"
                label="Is Queried Drug Randomized"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              

              <Form.Item
                name="combination"
                label="Primary drug combination"
                className="basis-1/3"
                rules={[{ required: false, message: "Please choose!" }]}
              >
                <Select options={COMBINATION_OPTIONS} />
              </Form.Item>
              <Form.Item
                name="therapeutic_asoociation"
                label="Therapeutic Association"
                rules={[
                  {
                    required: false,
                    message: "Please add Therapeutic Association!",
                  },
                ]}
                className="basis-1/3"
              >
                <Select options={THERAPEUTIC_ASSOCIATION_OPTIONS} />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="study_population"
                label="Study population"
                className="basis-full"
              >
                <Input.TextArea />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="n_patients"
                label="Num Patients, if Clinical (else write 'N/A')"
                className="basis-2/3"
              >
                <Input />
              </Form.Item>
            </div>
            <div className="flex gap-4">
              <Form.Item
                name="outcome_type"
                label="Outcome Type"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value="Survival">Survival</Radio>
                  <Radio value="Surrogate">Surrogate</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="serious_adverse_event"
                label="Serious Adverse Events?"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="is_difficult_to_validate"
                label="Is Difficult to Validate"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="difficult_to_validate_explanation"
                label="Difficult to Validate Explanation"
                className="basis-2/3"
              >
                <Input.TextArea />
              </Form.Item>
            </div>

            <div className="flex gap-4">
              <Form.Item
                name="overall_opinion"
                label="Overall Opinion"
                className="basis-1/3"
              >
                <Radio.Group optionType="button">
                  <Radio value="Yes">Yes</Radio>
                  <Radio value="No">No</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="reason_for_opinion"
                label="Reason for Opinion"
                className="basis-2/3"
              >
                <Input.TextArea />
              </Form.Item>
            </div>

            <Form.Item>
              <div className="flex gap-4">
                <Button
                  type="submit"
                  loading={submitMutation.isLoading}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((values) => {
                        form.resetFields();
                        handleSubmitWithNext(values);
                      })
                      .catch((info) => {
                        console.log("Validate Failed:", info);
                      });
                  }}
                  className="bg-blue-100 text-blue-600 hover:bg-blue-200 active:bg-blue-300"
                >
                  Submit & Next
                </Button>
                <Button loading={submitMutation.isLoading}>Submit</Button>
                <Button
                  onClick={() => {
                    deleteAnnotation.mutateAsync(id);
                    form.resetFields();
                  }}
                >
                  Reset
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

const ValidationCard = ({ data, onSelect }) => {
  return (
    <div
      className="border border-neutral-200 rounded-lg p-4 flex flex-col justify-between"
      //   onClick={() => onSelect(data)}
    >
      <div>
        <div className="flex gap-4 text-xs">
          {data.validation_status === "pending" ? (
            <span className="text-orange-500 bg-orange-100 px-2 py-1 rounded-md">
              Pending
            </span>
          ) : (
            <span className="text-green-500 bg-green-100 px-2 py-1 rounded-md">
              Completed
            </span>
          )}
          <div className="text-neutral-500 bg-neutral-100 px-2 py-1 rounded-md">
            PMID: {data.article.pubmed_id}
          </div>
          <div className="text-neutral-500 bg-neutral-100 px-2 py-1 rounded-md flex items-center gap-2">
            <Medicine02Icon className="w-4 h-4" /> {data.article.drug}
          </div>
        </div>
        <div className="text-sm text-neutral-500 mt-4 font-semibold">
          {data.article?.title}
        </div>
      </div>
      <div className="flex mt-4">
        <Button onClick={() => onSelect(data)}>Validate</Button>
      </div>
    </div>
  );
};
const ValidationView = () => {
  const match = useMatch("/v2/validation/:id");

  const [state, setState] = useState({
    loading: true,
    page: 1,
    search: "",
  });

  const [selectedTask, setSelectedTask] = useState(null);

  const [filter, setFilter] = useState({
    status: "",
    validator: "",
    search: "",
  });

  const validationTasks = useQuery(
    [
      "validation_tasks",
      state.page,
      state.search,
      filter.status,
      filter.validator,
    ],
    () =>
      ValidationTaskV2.getTasks({
        page: state.page,
        search: state.search,
        validation_set_id: match?.params.id,
        status: filter.status,
        validator: filter.validator,
      }),
    {}
  );
  const getNext = () => {
    ValidationTaskV2.getNext(selectedTask.id, {
      validation_set_id: match.params.id,
    }).then((res) => {
      setSelectedTask(res);
    });
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold text-neutral-800">
        Validation View
      </h2>

      <div className="mt-4 flex gap-4">
        <Input.Search
          placeholder="Search by article title"
          onSearch={(value) => {
            setState((prev) => ({ ...prev, search: value }));
          }}
        />
        <Select
          placeholder="Select validator"
          onChange={(value) => {
            setFilter((prev) => ({ ...prev, validator: value }));
          }}
        />
        <Select
          placeholder="Select status"
          onChange={(value) => {
            setFilter((prev) => ({ ...prev, status: value }));
          }}
        >
          <Select.Option value="">All</Select.Option>
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="completed">Completed</Select.Option>
        </Select>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4">
        {validationTasks.data && !validationTasks.isLoading
          ? validationTasks.data.results.map((task) => (
              <ValidationCard
                data={task}
                onSelect={() => setSelectedTask(task)}
                key={task.id}
              />
            ))
          : null}
      </div>

      <Pagination
        className="my-4 flex justify-end"
        current={state.page}
        pageSize={10}
        total={validationTasks.data?.total || 0}
        onChange={(page) => setState((prev) => ({ ...prev, page }))}
      />
      {selectedTask && (
        <ValidationModal
          id={selectedTask ? selectedTask.id : null}
          task={selectedTask ? selectedTask : null}
          getNext={getNext}
          onClose={() => setSelectedTask(null)}
        />
      )}
    </div>
  );
};

export default ValidationView;
