import { Modal } from "antd";
import { useAuth } from "../../authContext";
import PDFDocumentView from "../../components/pdfViewer";
import { useEffect, useState } from "react";
import { PubmedDataItems } from "../../services/dataService";

const PDFDocView = ({ docId, pmid, doi, visible,  onClose }) => {
  // const [fileBlob, setFileBlob] = useState(null);
  const [fileDetails, setFileDetails] = useState({
    fileBlob: null,
    annotations: null,
    loading: false,
  });
  const auth = useAuth();
  useEffect(() => {
    if ((docId, visible)) {
      // downloadPDF.mutate(docId);
      getFile();
    }
  }, [docId, visible]);

  const onSave = async (blob, annotations) => {
    // debugger
    // Save the PDF
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("annotations", JSON.stringify(annotations));
    formData.append("is_open", true);

    await PubmedDataItems.uploadPdf(docId, formData);
  };


  const getFile = async () => {
    setFileDetails({ loading: true });
    const annotations = await PubmedDataItems.getAnnotations(pmid || docId);
    const fileData = await PubmedDataItems.downloadPdf(pmid || docId);
    const fileBlob = new Blob([fileData], { type: "application/pdf" });
    setFileDetails({ fileBlob, annotations, loading: false });
  };

  return (
    <div>
      <Modal
        open={visible ? true : false}
        onCancel={() => onClose()}
        width={"calc(100vw - 40px)"}
        style={{ top: 20 }}
        className="pdf-modal"
        footer={null}
      >
        {/* <Button onClick={() => setFileBlob(null)}>Close</Button>
          <br />
          <br /> */}

        {fileDetails.loading && <div>Loading...</div>}
        {!fileDetails.loading && (
          <div style={{ height: "100vh" }}>
            <PDFDocumentView
              blob={fileDetails.fileBlob}
              title={`${pmid || doi}.pdf`}
              name={auth.user.username}
              email={auth.user.email}
              onSave={onSave}
              annotations={fileDetails.annotations}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PDFDocView;
