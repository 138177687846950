import React, { useState } from "react";

import LoginForm from "./signIn";
import SignUpForm from "./signUp";
import { Tabs, Row, Col } from "antd";
import Auth from "../../services/auth";
import TrainImg from "../../";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../authContext";
const { TabPane } = Tabs;

const LoginSignupPage = (props) => {
  const [flags, updateFlags] = useState({ activeTab: "1" });
  const auth = useAuth();
  const navigate = useNavigate();

  const login = async (username, password) => {
    let res = await auth.login(username, password);
    navigate("/", { replace: true });
    window.location.reload();
  };

  const signUp = (
    first_name,
    last_name,
    username,
    password,
    password2,
    email,
    role
  ) => {
    return auth.signup(
      first_name,
      last_name,
      username,
      password,
      password2,
      email,
      role
    );
  };

  return (
    <React.Fragment>
      <div
        style={{ maxWidth: "500px", margin: "100px auto", padding: "0px 25px" }}
      >
        <Row justify="center" align="middle" style={{ paddingBottom: "4rem" }}>
          {/* <img width={150} src="/trainfit.png" alt="image" /> */}
          <img
            src="/reboot-logo.png"
            style={{ height: "64px", marginTop: "0.5rem" }}
          />
        </Row>
        <Tabs
          value={flags.activeTab}
          onChange={(activeTab) => updateFlags({ ...flags, activeTab })}
        >
          <TabPane tab="Log In" key="1">
            <LoginForm onSubmit={login} />
          </TabPane>
          {/* <TabPane tab="Sign Up" key="2">
            <SignUpForm onSubmit={signUp} />
          </TabPane> */}
        </Tabs>
      </div>
    </React.Fragment>
  );
};

export default LoginSignupPage;
