import React from "react";
import { useQuery } from "react-query";
import { PubmedDataItems } from "../../services/dataService";
import { Divider, Row, Typography } from "antd";

const COLUMN_MAP = {
  relevance: "Relevance",
  study_type: "Study Type",
  therapeutic_asoociation: "Therapeutic Association",
  combination: "Primary drug combination status",
  queried_drug_randomized: "Is Queried Drug Primary Difference between arms",
  n_patients: "Num Patients, if Clinical",
  tissue_type: "Tissue Type",
  tissue_stage: "Tissue Stage",
  study_population: "Study population",
  outcome: "Outcome",
  serious_adverse_event: "Serious Adverse Events?",
  overall_opinion: "Overall Opinion",
  reason_for_opinion: "Reason for Opinion",
};

const DataRow = ({ item, dataKey }) => {
  return (
    <div style={{
        maxWidth: "500px",
      }} >
      <Row
        key={dataKey}
        justify="space-between"
       
      >
        <Typography.Text type="secondary">
          {COLUMN_MAP[dataKey]}
        </Typography.Text>
        <span>{item[dataKey]}</span>
      </Row>
      <Divider
        style={{
          margin: "6px 0",
        }}
      />
    </div>
  );
};

const ValidationView = ({ itemId }) => {
  const validationData = useQuery("validationData", async () => {
    return PubmedDataItems.getValidations(itemId);
  });
  return (
    <div>
      {validationData.data &&
        validationData.data?.results.map((item) => (
          <div key={item.id}>
            <Typography.Title level={5}>
              Validated By: {item.validator}
            </Typography.Title>

            {Object.keys(COLUMN_MAP).map((key) => {
              return <DataRow item={item.annotation} key={key} dataKey={key} />;
            })}
          </div>
        ))}
    </div>
  );
};

export default ValidationView;
