import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  Input,
  List,
  Radio,
  Select,
  message,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import ResponseForm from "./responseForm";
import createPersistedState from "use-persisted-state";
import CustomHeader from "../components/header";
import { HelperServices, LabelItem, LabelTask } from "../services/dataService";
// import { Header } from "antd/es/layout/layout";
const labelAppState = createPersistedState("labelAppState");
const globalAppState = createPersistedState("globalAppState");

function LabelHome(props) {
  const match = useMatch("/label/:id");

  const queryClient = useQueryClient();

  const [state, setState] = labelAppState({
    loading: true,
    page: 1,
    search: "",
    pageSize: 10,
  });
  const ref = useRef(null);

  useEffect(() => {


    return setState((prev) => ({ ...prev, selected: null }));
  }, []);

//   useEffect(() => {
//     // ref.current?.scrollIntoView({
//     //   block: "start",
//     //   inline: "nearest",
//     //   behavior: "smooth",
//     // });
//   }, [state.selected]);

  const labelTaskDetail = useQuery(
    ["label_task_detail", match.params.id],
    () => LabelTask.get(match.params.id),
    { enabled: match.params.id != undefined }
  );

  const labelItems = useQuery(
    [
      "label_items",
      state.page,
      state.search,
      state.task_id,
      state.labelState,
      state.labelledBy,
      state.pageSize
    ],
    () =>
      LabelItem.getAll({
        page: state.page,
        search: state.search,
        task_id: match.params.id,
        label_state: state.labelState,
        labelled_by: state.labelledBy,
        page_size: state.pageSize
      }),
    {
      staleTime: Infinity,
      onSuccess: (data) => {
        setState((prev) => ({ ...prev, selected: data.results[0], loading: false }));
      },
    }
  );

  useEffect(() => {
    if (labelItems.data) {
      setState((prev) => ({ ...prev, selected: labelItems.data.results[0] }));
    }
  }, [labelItems.data]);


  const nextLabelItem = useMutation(
    () =>
      LabelItem.get_next(state.selected.id, {
        acquire_lock: true,
        search: state.search,
        label_state: state.labelState,
        labelled_by: state.labelledBy,
        is_labelled: false,
        task_id: state.selected.task_id,
      }),
    {
      onError: (error) => {
        console.log(error);
        message.error("No Next Item found");
      },
      onSuccess: (data) => {
        // props.onNext(data);
      },
    }
  );

  const loadAndMoveToNext = async () => {
    nextLabelItem.mutateAsync().then((data) => {
      setState((prev) => ({ ...prev, selected: data }));
    });

    //  props.onNext(nextLabelItem.data);
  };

  const userList = useQuery("user_list", () => HelperServices.getAllUsers(), {
    enabled: true,
  });

  const viewOptions = [
    { label: "List", value: "listView" },
    { label: "Single", value: "singleView" },
  ];

  return (
    <div>
      <CustomHeader />
      <div style={{ maxWidth: "1200px", margin: "0px auto" }}>
        {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
        <Row>
          <Row style={{ width: "100%" }}>
            <Typography.Title level={4}>
              <a>Labeltool</a> {">"} {labelTaskDetail.data?.task_name}
            </Typography.Title>
          </Row>
          <Row style={{ width: "100%" }} gutter={12}>
            <Col span={8}>
              <Input.Search
                onSearch={(e) => {
                  setState((prev) => ({ ...prev, search: e, page: 1, selected: null }));
                }}
              ></Input.Search>
            </Col>
            <Col span={16}>
              <Radio.Group
                options={viewOptions}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    splitView: e.target.value === "singleView",
                  }));
                }}
                value={state.splitView ? "singleView" : "listView"}
                optionType="button"
                // buttonStyle="solid"
              />
              <Select
                style={{ marginLeft: "1rem", width: "150px" }}
                value={state.labelState}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, labelState: e, page: 1,  selected: null  }));
                }}
                allowClear
                placeholder="Label State"
              >
                <Select.Option value="all">All</Select.Option>
                <Select.Option value="labelled">Labelled</Select.Option>
                <Select.Option value="pending">Pending</Select.Option>
              </Select>
              <Select
                style={{ marginLeft: "1rem", width: "100px" }}
                allowClear
                value={state.labelledBy}
                onChange={(e) => {
                  setState((prev) => ({ ...prev, labelledBy: e, page: 1,  selected: null  }));
                }}
              >
                {
                  // Get all users and populate here
                  userList.data?.map((user) => (
                    <Select.Option value={user} key={user}>{user}</Select.Option>
                  ))
                }
              </Select>
              <Button
                style={{ marginLeft: "1rem" }}
                onClick={() => loadAndMoveToNext()}
              >
                Load Next
              </Button>
            </Col>
          </Row>
          {state.splitView ? (
            <Row style={{ width: "100%" }}>
              <Col
                span={12}
                style={{ width: "100%", height: "90vh", overflowY: "scroll" }}
              >
                {state.selected && (
                  <div style={{ padding: "1.5rem 1.5rem" }}>
                    <div ref={ref}>
                      {state.selected?.labelled_at ? (
                        <Tag color="green">Labelled</Tag>
                      ) : (
                        <Tag color="yellow">Pending</Tag>
                      )}
                      <Tag color="green">{state.selected?.drug_name}</Tag>
                      <Tag color="geekblue">{state.selected?.pubmed_id}</Tag>
                    </div>
                    <Typography.Title level={3}>
                      {state.selected.title}
                    </Typography.Title>
                    <Typography.Text>
                      {state.selected?.abstract}
                    </Typography.Text>

                    {/* <Button onClick={() => loadNext()}>Load Next</Button> */}
                  </div>
                )}
              </Col>
              <Col
                span={12}
                style={{ width: "100%", height: "90vh", overflowY: "scroll" }}
              >
                <ResponseForm
                  itemId={state.selected?.id}
                  taskId={labelTaskDetail.data?.id}
                  pmid={state.selected?.pubmed_id}
                  getNext={nextLabelItem}
                  existingLabels={state.selected?.labels}
                  drugName={state.selected?.drug_name}
                  //   onNext={(item) => {
                  //     console.log("Item", item);
                  //     setState((prev) => ({
                  //       ...prev,
                  //       selected: item,
                  //       selectedIdx: prev.selectedIdx + 1,
                  //     }));
                  //   }}
                  onNext={loadAndMoveToNext}
                />
              </Col>
            </Row>
          ) : (
            <Row style={{ width: "100%" }}>
              <Col
                span={8}
                style={{
                  width: "100%",
                  height: "90vh",
                  overflowY: "scroll",
                  // padding: "0px 20px",
                }}
              >
                <List
                  pagination={{
                    onChange: (page, pageSize) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page,
                        pageSize: pageSize,
                      }));
                    },
                    total: labelItems.data ? labelItems.data.total : 0,
                    current: state.page,
                    pageSize: state.pageSize || 10,
                  }}
                  grid={{
                    gutter: 2,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  dataSource={labelItems.data ? labelItems.data.results : []}
                  // dataSource={labelItems.data}
                  loading={labelItems.isLoading}
                  renderItem={(item, idx) => (
                    <List.Item key={idx}>
                      <div key={item?.pubmed_id}>
                        <Card
                          style={{ margin: "1rem 0rem" }}
                          onClick={() =>
                            setState((prev) => ({
                              ...prev,
                              selected: item,
                              selectedIdx: idx,
                            }))
                          }
                        >
                          <Tag
                            color="geekblue"
                            onClick={() =>
                              window.open(
                                `https://pubmed.ncbi.nlm.nih.gov/${item["External ID"]}/`,
                                "_blank"
                              )
                            }
                          >
                            PMID: {item.pubmed_id}
                          </Tag>
                          <Tag color="green">{item?.drug_name}</Tag>

                          {item.labelled_at ? (
                            <Tag color="green">Labelled</Tag>
                          ) : (
                            <Tag color="yellow">Pending</Tag>
                          )}
                          <br />{" "}
                          <div style={{ marginTop: "0.5rem" }}>
                            {item["title"]}
                          </div>
                        </Card>
                      </div>
                    </List.Item>
                  )}
                />
              </Col>
              <Col
                span={16}
                //Set scrollbar width to 0.125 rem
                style={{ width: "100%", height: "90vh", overflowY: "scroll" }}
              >
                {state.selected && (
                  <div style={{ margin: "1.5rem 1.5rem" }}>
                    <div ref={ref}>
                      {state.selected.labelled_at ? (
                        <Tag color="green">Labelled</Tag>
                      ) : (
                        <Tag color="yellow">Pending</Tag>
                      )}
                    </div>
                    <Typography.Title level={3}>
                      {state.selected.title}
                    </Typography.Title>
                    <Typography.Text>
                      {state.selected?.abstract}
                    </Typography.Text>
                    <ResponseForm
                      itemId={state.selected?.id}
                      taskId={labelTaskDetail.data?.id}
                      pmid={state.selected?.pubmed_id}
                      getNext={loadAndMoveToNext}
                      existingLabels={state.selected?.label_data}
                      drugName={state.selected?.drug_name}
                      //   onNext={(item) => {
                      //     console.log("Item", item);
                      //     setState((prev) => ({
                      //       ...prev,
                      //       selected: item,
                      //       selectedIdx: prev.selectedIdx + 1,
                      //     }));
                      //   }}
                      onNext={loadAndMoveToNext}
                    />
                    {/* <Typography.Title level={3}>Predictions</Typography.Title>
                  {state.selected.answers.map((item) => (
                    <div style={{margin: "0.5rem"}}>
                      {" "}
                      <Typography.Text><b>Question: {item.question}</b> </Typography.Text><br/>
                      <ReactMarkdown remarkPlugins={[remarkGfm]}>{"Answer:\n" + item.subjective_answer}</ReactMarkdown> 

                      Objective: {item.objective}<br/>
                    </div>
                  ))}
                  <Typography.Text>
                    {state.selected?.predictions}
                  </Typography.Text> */}
                    {/* <Button onClick={() => loadNext()}>Load Next</Button> */}
                  </div>
                )}
              </Col>
            </Row>
          )}
        </Row>
      </div>
      {/* </header> */}
    </div>
  );
}

export default LabelHome;
