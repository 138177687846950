import { twMerge } from "tailwind-merge";
import { Loading03Icon } from "./icons";

export const SmallButton = ({ children, onClick, disabled, type, className }) => (
  <button
    className={twMerge(
      `text-xs px-2 py-1 rounded-md bg-neutral-100 text-neutral-700 transition hover:bg-neutral-200 active:bg-neutral-300`,
      disabled ? "opacity-50 cursor-not-allowed" : " cursor-pointer",
      className
    )}
    onClick={onClick}
    disabled={disabled}
    type={type || "button"}
  >
    {children}
  </button>
);

export const Button = ({ children, disabled, onClick, loading, type, className }) => {
  return (
    <button
      className={twMerge(
        `text-sm px-2 py-1 rounded-md bg-neutral-100 text-neutral-600 transition hover:bg-neutral-200 active:bg-neutral-300 flex items-center gap-2`,
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer",
        loading ? "cursor-not-allowed opacity-50" : "",
        className
      )}
      onClick={onClick}
      disabled={disabled || loading}
      type={type || "button"}
    >
      {loading && <Loading03Icon className="animate-spin h-4 w-4" />}
      {children}
    </button>
  );
};
