import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  Input,
  List,
  Radio,
  Select,
  Table,
  Popover,
  Checkbox,
  Drawer,
} from "antd";
import { useEffect, useRef, useState } from "react";
import createPersistedState from "use-persisted-state";
import PubDetailView from "./detailView";
import { PublicationCard } from "../pubmedDataV2";
const useTableState = createPersistedState("tableState");

const columns = [
  {
    title: "Pubmed ID",
    dataIndex: "pubmed_id",
    key: "pubmed_id",
    render: (text, record) => (
      <Popover
        content={
          <div
            style={{ maxWidth: "600px", maxHeight: "300px", overflow: "auto" }}
          >
            <p>Pubmed ID: {text}</p>
            <p>
              Pubmed URL:{" "}
              <a
                href={`https://pubmed.ncbi.nlm.nih.gov/${text}/`}
                target="_blank" rel="noreferrer"
              >
                https://pubmed.ncbi.nlm.nih.gov/{text}/
              </a>
            </p>
            <p>Abstract: {record.abstract}</p>
          </div>
        }
      >
        <Tag
          color="geekblue"
          onClick={() =>
            window.open(`https://pubmed.ncbi.nlm.nih.gov/${text}/`, "_blank")
          }
        >
          {text}
        </Tag>
      </Popover>
    ),
  },
  {
    title: "Drug Name",
    dataIndex: "drug",
    key: "drug",
  },
  {
    title: "Authors",
    dataIndex: "authors",
    key: "authors",
    render: (authors, record) => (authors ? authors.join(", ") : ""),
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Cancer Type",
    // dataIndex: "gpt_data.cancer_type",
    render: (text, record) => <span>{record.gpt_data.cancer_type}</span>,
    key: "gpt_data.cancer_type",
  },
  {
    title: "Study Type",
    // dataIndex: "gpt_data.study_type",
    render: (text, record) => <span>{record.gpt_data.study_type}</span>,
    key: "gpt_data.study_type",
  },
  {
    title: "Outcomes",
    // dataIndex: "gpt_data.study_type",
    render: (text, record) => (
      <ul>
        {record.gpt_data.endpoint_outcomes?.map((outcome) => {
          return <li key={outcome.outcome_name}>{outcome.outcome_name}</li>;
        })}
      </ul>
    ),
    key: "gpt_data.study_type",
  },
  {
    title: "Patient Num",
    // dataIndex: "gpt_data.patient_num",
    render: (text, record) => <span>{record.gpt_data.patient_num}</span>,

    key: "gpt_data.patient_num",
  },
  {
    title: "GPT Relevance",
    // dataIndex: "gpt_data.study_type",
    render: (text, record) => <ul>{record.gpt_data.relevance}</ul>,
    key: "gpt_data.relevance",
  },
  {
    title: "GPT Relevance Explanation",
    // dataIndex: "gpt_data.study_type",
    render: (text, record) => <ul>{record.gpt_data.relevance_explanation}</ul>,
    key: "gpt_data.relevance_explanation",
  },
];

const TableView = ({ data, total, onPageChange }) => {
  const [state, setState] = useState({});
  let actionColumn = {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <Button
      type="link"
        onClick={() => {
          setState((prev) => ({
            ...prev,
            selected: record,
          }));
        }}
      >
        Open
      </Button>
    ),
  };

  const [selectedColumns, setColumns] = useTableState(
    columns.map((column) => column.key)
  );

  return (
    <div
      style={{
        marginTop: "12px",
      }}
    >
      <Drawer 
        title="Pubmed Data Detail"
        placement="right"
        closable={true}
        onClose={() => setState((prev) => ({ ...prev, selected: null }))}
        visible={state.selected}
        width={600}
      >
        {/* <PubDetailView selected={state.selected}></PubDetailView>
         */}
         <PublicationCard item={state.selected} 
         onTabChange={
            (key) => {
              console.log("Tab changed", key)
            }

         }
         ></PublicationCard>
      </Drawer>
      <Popover
        content={
          <div
            style={{
              width: 300,
            }}
          >
            <Row>
              <Checkbox.Group
                value={selectedColumns}
                onChange={(checkedValues) => {
                  console.log("checked = ", checkedValues);
                  setColumns(checkedValues);
                }}
              >
                <Row>
                  {columns.map((column) => {
                    return (
                      <Col span={24} key={column.key}>
                        <Checkbox value={column.key}>{column.title}</Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Row>
          </div>
        }
        title="Select Columns"
        trigger="click"
      >
        <Button>Select Columns</Button>
      </Popover>
      <Table
        columns={[...columns.filter((column) =>
          selectedColumns.includes(column.key)
        ), actionColumn]}
        dataSource={data}
        pagination={{
          pageSize: 10,
          showSizeChanger: false,
          total: total,
          onChange: (page, pageSize) => {
            // console.log(page, pageSize);
            onPageChange(page, pageSize);
          },
        }}
      />
    </div>
  );
};
export default TableView;
