import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { useQuery } from "react-query";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import chroma from "chroma-js";
import FilterViewV2 from "../../../pages/pubmedData/filterViewV2";
import { DocService } from "../../../services/dataService";
import SearchFilter from "../dashboard/components/filter";
import { useFilters } from "../../../hooks/filters";


const KEY_NAMES = {
  'tissue': 'nccn_tissue_type.raw', 
  'drug': 'drug.raw',
  'combination': 'extraction.combination.raw',
  'study_type': 'extraction.study_type.raw',
  'ta': 'extraction.ta.raw',
  'relevant': 'relevance_data.relevance_flag.raw',
  'published_year': 'published_year',
}
const generateColors = (n) => {
  // return chroma.scale(['#115f9a', '#d0f400']).colors(n);
  // return chroma.scale([ '#9080ff', '#ffb400']).colors(n);
  return chroma.scale(["#e27c7c", "#a86464", "#599e94", "#6cd4c5"]).colors(n);
};

const KeyMapping = {
  study_type: "Study Type",
  ta_for_drug: "Therapeutic Association for Query Drug",
  ta: "Therapeutic Association",
};
const StatsBarChart = ({ data, selectedKey, breakdownKey, onFilterAdd }) => {
  let uniqueKeys = [];
  selectedKey = KEY_NAMES[selectedKey];


  let process_data = data.map((item) => {
    let temp = {
      // name: item[breakdownKey],
      name: item["key"],
    };
    item[selectedKey].forEach((element) => {
      temp[element["key"]] = element["doc_count"];

      // Add unique keys
      if (!uniqueKeys.includes(element["key"])) {
        uniqueKeys.push(element["key"]);
      }
    });
    return temp;
  });

  let colors = generateColors(uniqueKeys.length);

  process_data = process_data.slice(0, 10);
  return (
    <div className="h-[450px] w-full pb-8">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={process_data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
          barSize={12}
          barGap={24}
          onClick={(e) => console.log(e)}
        >
          {uniqueKeys.length <= 10 && (
            <Legend
              verticalAlign="top"
              iconSize={10}
              wrapperStyle={{
                fontSize: "14px",
              }}
            />
          )}

          <XAxis type="number" hide />
          <YAxis
            dataKey="name"
            type="category"
            axisLine={false}
            width={150}
            tick={{ fontSize: 14 }}
          />
          <Tooltip />
          {uniqueKeys.map((key) => {
            return (
              <Bar
                key={key}
                dataKey={key}
                onClick={(e) => {
                  onFilterAdd({
                    key: key,
                    breakdownKey: e.name,
                  });
                }}
                stackId="a"
                fill={colors[uniqueKeys.indexOf(key)]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

// const Scoring = () => {
//   const { filters } = useFilters();
//   const [selectedTissue, setSelectedTissue] = useState("Prostate");
//   const scoringData = useQuery("scoringData", () => {
//     return DocService.scoringData();
//   });

//   let overallScores = scoringData.data?.score;
//   let breakdown = scoringData.data?.breakdown;
//   let processedData = overallScores
//     ?.filter((item) => {
//       if (selectedTissue === "All") {
//         return true;
//       } else {
//         return item.tissuename === selectedTissue;
//       }
//     })
//     .map((item) => {
//       item.name = item.drug;
//       return item;
//     });

//   let studyTypeData = [];
//   let uniqueStudyTypes = [];
//   let yearWiseData = [];
//   let uniqueYears = [];
//   let taData = [];
//   let uniqueTAs = [];
//   breakdown
//     ?.filter((item) => {
//       return item.tissuename === selectedTissue;
//     })
//     .forEach((item) => {
//       item.name = item.drug;

//       studyTypeData.push({
//         name: item.name,
//         ...item.study_type,
//       });
//       taData.push({
//         name: item.name,
//         ...item.ta,
//       });

//       yearWiseData.push({
//         name: item.name,
//         ...item.publication_year,
//       });

//       Object.keys(item.study_type).forEach((element) => {
//         if (!uniqueStudyTypes.includes(element)) {
//           uniqueStudyTypes.push(element);
//         }
//       });

//       Object.keys(item.ta).forEach((element) => {
//         if (!uniqueTAs.includes(element)) {
//           uniqueTAs.push(element);
//         }
//       });

//       Object.keys(item.publication_year || {}).forEach((element) => {
//         if (!uniqueYears.includes(element)) {
//           uniqueYears.push(element);
//         }
//       });
//     });

//   let uniqueTissue = [];
//   overallScores?.forEach((item) => {
//     if (!uniqueTissue.includes(item.tissuename)) {
//       uniqueTissue.push(item.tissuename);
//     }
//   });

//   let uniqueStudyTypesColors = generateColors(uniqueStudyTypes.length);
//   let uniqueTAsColors = generateColors(uniqueTAs.length);
//   let uniqueYearsColors = generateColors(uniqueYears.length);
//   return (
//     <div>
//       <div className="flex grid grid-cols-2  gap-8">
//         <div className="h-[450px] overflow-y-auto basis-1/2 bg-white rounded-md p-4 border shadow-sm rounded-md ">
//           <div className="flex justify-center items-center mt-2">
//             <h3 className="text-base font-semibold">Overall Score</h3>
//           </div>
//           {scoringData.isLoading ? (
//             <div className="h-full  flex items-center justify-center">
//               <Spin />
//             </div>
//           ) : null}
//           {!scoringData.isLoading && (
//             <div
//               // style={{
//               //   height: processedData?.length * 50 + "px",
//               // }}
//               className="p-4 w-full h-full"
//             >
//               <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                   width={500}
//                   height={300}
//                   data={processedData.slice(0, 10)}
//                   margin={{
//                     top: 20,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                   layout="vertical"
//                   barSize={12}
//                   onClick={(e) => console.log(e)}
//                 >
//                   {/* <CartesianGrid strokeDasharray="3 3" /> */}
//                   <XAxis type="number" orientation="top" />
//                   <YAxis
//                     dataKey="name"
//                     type="category"
//                     axisLine={false}
//                     width={100}
//                     tick={{ fontSize: 14 }}
//                   />
//                   <Tooltip />

//                   <Bar dataKey="total_score" fill="#0a9396" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//           )}
//         </div>
//         <div className="h-[450px] overflow-y-auto basis-1/2  bg-white rounded-md p-4 border shadow-sm rounded-md ">
//           <div className="flex justify-center items-center mt-2">
//             <h3 className="text-base font-semibold">Study Types</h3>
//           </div>
//           {scoringData.isLoading ? (
//             <div className="h-full  flex items-center justify-center">
//               <Spin />
//             </div>
//           ) : null}
//           {!scoringData.isLoading && (
//             <div
//               // style={{
//               //   height: processedData?.length * 50 + "px",
//               // }}
//               className="p-4 w-full h-full"
//             >
//               <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                   width={500}
//                   height={300}
//                   data={studyTypeData.slice(0, 10)}
//                   margin={{
//                     top: 20,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                   layout="vertical"
//                   barSize={12}
//                   onClick={(e) => console.log(e)}
//                 >
//                   <Legend
//                     verticalAlign="top"
//                     style={{
//                       display: "fixed",
//                     }}
//                     wrapperStyle={{
//                       fontSize: "14px",
//                     }}
//                   />

//                   {/* <CartesianGrid strokeDasharray="3 3" /> */}
//                   <XAxis type="number" orientation="top" />
//                   <YAxis
//                     dataKey="name"
//                     type="category"
//                     axisLine={false}
//                     width={100}
//                     tick={{ fontSize: 14 }}
//                   />
//                   <Tooltip />
//                   {uniqueStudyTypes.map((key) => {
//                     return (
//                       <Bar
//                         key={key}
//                         dataKey={key}
//                         stackId="a"
//                         fill={
//                           uniqueStudyTypesColors[uniqueStudyTypes.indexOf(key)]
//                         }
//                       />
//                     );
//                   })}
//                   {/* <Bar dataKey="total_score" fill="#0a9396" /> */}
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//           )}
//         </div>
//         <div className="h-[450px] overflow-y-auto basis-1/2  bg-white rounded-md p-4 border shadow-sm rounded-md ">
//           <div className="flex justify-center items-center mt-2">
//             <h3 className="text-base font-semibold">Therapeutic Association</h3>
//           </div>
//           {scoringData.isLoading ? (
//             <div className="h-full w-full flex items-center justify-center">
//               <Spin />
//             </div>
//           ) : null}
//           {!scoringData.isLoading && (
//             <div
//               // style={{
//               //   height: processedData?.length * 50 + "px",
//               // }}
//               className="p-4 w-full h-full"
//             >
//               <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                   width={500}
//                   height={300}
//                   data={taData.slice(0, 10)}
//                   margin={{
//                     top: 20,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                   layout="vertical"
//                   barSize={12}
//                   onClick={(e) => console.log(e)}
//                 >
//                   <Legend
//                     verticalAlign="top"
//                     style={{
//                       display: "fixed",
//                     }}
//                     wrapperStyle={{
//                       fontSize: "14px",
//                     }}
//                   />

//                   {/* <CartesianGrid strokeDasharray="3 3" /> */}
//                   <XAxis type="number" orientation="top" />
//                   <YAxis
//                     dataKey="name"
//                     type="category"
//                     axisLine={false}
//                     width={100}
//                     tick={{ fontSize: 14 }}
//                   />
//                   <Tooltip />
//                   {uniqueTAs.map((key) => {
//                     return (
//                       <Bar
//                         key={key}
//                         dataKey={key}
//                         stackId="a"
//                         fill={uniqueTAsColors[uniqueTAs.indexOf(key)]}
//                       />
//                     );
//                   })}
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//           )}
//         </div>
//         <div className="h-[450px] overflow-y-auto basis-1/2  bg-white rounded-md p-4 border shadow-sm rounded-md ">
//           <div className="flex justify-center items-center mt-2">
//             <h3 className="text-base font-semibold">Publication Year</h3>
//           </div>
//           {scoringData.isLoading ? (
//             <div className="h-full w-full flex items-center justify-center">
//               <Spin />
//             </div>
//           ) : null}
//           {!scoringData.isLoading && (
//             <div
//               // style={{
//               //   height: processedData?.length * 50 + "px",
//               // }}
//               className="p-4 w-full h-full"
//             >
//               <ResponsiveContainer width="100%" height="100%">
//                 <BarChart
//                   width={500}
//                   height={300}
//                   data={yearWiseData.slice(0, 10)}
//                   margin={{
//                     top: 20,
//                     right: 30,
//                     left: 20,
//                     bottom: 5,
//                   }}
//                   layout="vertical"
//                   barSize={12}
//                   onClick={(e) => console.log(e)}
//                 >
//                   {/* <Legend
//                     verticalAlign="top"
//                     style={{
//                       display: "fixed",
//                     }}
//                   /> */}

//                   {/* <CartesianGrid strokeDasharray="3 3" /> */}
//                   <XAxis type="number" orientation="top" />
//                   <YAxis
//                     dataKey="name"
//                     type="category"
//                     axisLine={false}
//                     width={100}
//                     tick={{ fontSize: 14 }}
//                   />
//                   <Tooltip />
//                   {uniqueYears.map((key) => {
//                     return (
//                       <Bar
//                         key={key}
//                         dataKey={key}
//                         stackId="a"
//                         fill={uniqueYearsColors[uniqueYears.indexOf(key)]}
//                       />
//                     );
//                   })}
//                 </BarChart>
//               </ResponsiveContainer>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
const ScoringView = () => {
  const { search, filters, setFilters } = useFilters();
  const stats = useQuery(["tissueStats", search, filters], () => {
    return DocService.getAggregatedData({
      filters: JSON.stringify(filters),
      search,
    });
  });

  
  useEffect(() => {}, [filters]);

  return (
    <div className="">
      <div className="mx-auto">
        <div className="my-4">
          <SearchFilter />
        </div>
        <div className="mx-4">
          <div className="text-lg font-semibold mt-12 mb-8">
            Tissue Breakdown
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">Study Type</div>
                {stats.data?.[KEY_NAMES['tissue']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['tissue']]}
                    selectedKey={"study_type"}
                    breakdownKey={"tissue"}
                    onFilterAdd={(e) => {
                      // addFilter("extraction.study_type", e.name);
                      setFilters((prev) => ({
                        ...prev,
                        "extraction.primary_tissue_type": e.breakdownKey,
                        "extraction.study_type": e.key,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">
                  Therapeutic Association
                </div>

                {stats.data?.[KEY_NAMES['tissue']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['tissue']]}
                    selectedKey={"ta"}
                    breakdownKey={"tissue"}
                    onFilterAdd={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        "extraction.primary_tissue_type": e.breakdownKey,
                        "extraction.ta": e.key,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          <div className="text-xl font-semibold mt-24 mb-8">Drug Breakdown</div>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">Study Type</div>
                {stats.data?.[KEY_NAMES['drug']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['drug']]}
                    selectedKey={"study_type"}
                    breakdownKey={"drug"}
                    onFilterAdd={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        "extraction.study_type": e.key,
                        "drug": e.breakdownKey,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">
                  Therapeutic Association
                </div>

                {stats.data?.[KEY_NAMES['drug']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['drug']]}
                    selectedKey={"ta"}
                    breakdownKey={"drug"}
                    onFilterAdd={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        "drug": e.breakdownKey,
                        "extraction.ta": e.key,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">
                  Publication Year
                </div>

                {stats.data?.[KEY_NAMES['drug']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['drug']]}
                    selectedKey={"published_year"}
                    breakdownKey={"drug"}
                    onFilterAdd={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        "drug": e.breakdownKey,
                        "published_year": e.key,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
            <Col span={12}>
              <div className="p-4 border shadow-sm rounded-md bg-white ">
                <div className="text-[0.925rem] text-gray-600 ">
                  Tissue Breakdown
                </div>

                {stats.data?.[KEY_NAMES['drug']] ? (
                  <StatsBarChart
                    data={stats.data?.[KEY_NAMES['drug']]}
                    selectedKey={"tissue"}
                    breakdownKey={"drug"}
                    onFilterAdd={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        "drug": e.breakdownKey,
                        "extraction.primary_tissue_type": e.key,
                      }));
                    }}
                  />
                ) : null}
              </div>
            </Col>
          </Row>

          {/* <Scoring /> */}
          {/* </Tabs.TabPane> */}
          {/* <Tabs.TabPane tab="Tab 3" key="3">
            Content of Tab Pane 3
          </Tabs.TabPane> */}
          {/* </Tabs> */}
        </div>
      </div>
    </div>
  );
};

export default ScoringView;
