import React, { useEffect, useRef, useState } from "react";
import { Input, Button, Space, message } from "antd";
// import "./index.css";
import { PubmedDataItems } from "../../../services/dataService";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "../../../authContext";
import moment from "moment";

const CommentSection = ({ docId, comments, pubmedId }) => {
  const auth = useAuth();
  const [highlights, setHighlights] = useState([]);
  const [sekection, setSelection] = useState(null); // [start, end
  const ref = useRef(null);
  const highlightRef = useRef(null);
  const [commentText, setCommentText] = useState("");

  const loadComments = useQuery(
    ["comments", docId],
    () => PubmedDataItems.getComments(docId),
    {}
  );

  const saveComment = useMutation(
    (data) => PubmedDataItems.saveComment(docId, data),
    {
      onSuccess: (data) => {
        loadComments.refetch();
        message.success("Comment saved");
        setCommentText("");
        //   queryClient.invalidateQueries(["comments", docId]);
      },
    }
  );

  const createComment = () => {
    saveComment.mutate({
      docId,
      pubmed_id: pubmedId,
      comment: commentText,
      comment_by: auth.user.username,
    });
  };
  return (
    <div className="highlighter">
      <div
      // className="inline-toolbar"
      // style={{
      //   display: !sekection?.top ? "none" : "block",
      //   bottom: sekection?.top,
      //   left: sekection?.left,
      //   //   height: "60px",
      //   width: "300px",
      // }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input.TextArea
            rows={2}
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          />
          <Button size="small" onClick={createComment}>
            Save
          </Button>
        </Space>
      </div>
      {/* <div className="text-container" ref={ref}>
        {abstract}
      </div> */}
      <Space direction="vertical" style={{ width: "100%", margin: "24px 0px" }}>
        {loadComments?.data?.results?.map((comment) => {
          return (
            <div key={comment.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>{comment.comment}</div>

                {auth.user.username === comment.comment_by && (
                  <Button
                    size="small"
                    type="link"
                    danger
                    onClick={() => {
                      PubmedDataItems.deleteComment(comment.id).then(() => {
                        loadComments.refetch();
                      });
                    }}
                  >
                    Delete
                  </Button>
                )}
              </div>
              <div
                style={{
                  fontSize: "12px",
                  color: "#999",
                  //   textAlign: "right",
                  fontStyle: "italic",
                }}
              >
                {comment.comment_by} -{" "}
                {moment(comment.created_at).format("DD MMM YYYY")}
              </div>
            </div>
          );
        })}
      </Space>
    </div>
  );
};

export default CommentSection;
