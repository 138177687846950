import React from "react";
import {
  Bookmark01Icon,
  Calendar03Icon,
  DnaIcon,
  Edit02Icon,
  Folder01Icon,
  LicenseIcon,
  Link04Icon,
  LinkSquare01Icon,
  Loading03Icon,
  Medicine02Icon,
  News01Icon,
  Pdf01Icon,
  Upload02Icon,
  UserMultiple02Icon,
} from "../../../components/icons";
import { Input, message, Tabs } from "antd";
import PDFDocView from "../../../../pages/pubmedData/DocView";
import BookMarkModal from "../../../components/bookMarkModal";
import UploadPDF from "../../../../pages/pubmedData/importPDF";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { BookmarkService, DocService } from "../../../../services/dataService";
import moment from "moment";
import { useAuth } from "../../../../authContext";
import ReactJson from "react-json-view";

const SUMMARY_KEY = [
  "objective",
  "population_characteristics",
  "methods",
  "endpoints_measured",
  "conclusion",
];

const FILTER_KEYS = ["tissue_type"];
const SUMMARY_KEY_LABEL = {
  objective: "Objective",
  population_characteristics: "Population Characteristics",
  methods: "Methods",
  endpoints_measured: "Endpoints Measured",
  conclusion: "Conclusions",
};

const KEY_LABEL = {
  endpoints: "Endpoints",
  relevant_endpoints: "Relevant Endpoints",
  study_type: "Study Type",
  primary_tissue_type: "Primary Tissue Type",
  drugs_studied: "Drugs Studied",
  cancer_types: "Cancer Types Studied",
  is_queried_drug_evaluated: "Is Queried Drug Evaluated",
  num_patients: "Number of Patients",
  ta: "Therapeutic Association",
  relevance: "Relevance",
  is_adhoc_or_posthoc: "Is a Ad-Hoc/Post-Hoc Study",
  combination: "Combination",
};

const RenderKey = ({ label, value }) => {
  if (typeof value === "boolean") {
    value = value ? "Yes" : "No";
  }

  if (Array.isArray(value)) {
    if (value.length === 0) {
      value = (
        <span className="text-sm text-neutral-400">No value extracted</span>
      );
    } else {
      value = value.map((v) => (
        <span key={v} className="bg-neutral-100 px-2 rounded-md text-sm">
          {v}
        </span>
      ));
    }
  }

  if (value === null || value === undefined || value === "") {
    value = (
      <span className="text-sm text-neutral-400">No values extracted</span>
    );
  }

  return (
    <div className="flex  gap-1 border-b border-neutral-200 py-2 w-[600px]">
      <div className="text-sm font-semibold w-1/2">{label}</div>
      <div className="text-sm w-1/2 flex flex-wrap gap-1">{value}</div>
    </div>
  );
};

export const ExtractedDetails = ({ article }) => {
  return (
    <div className="text-base text-neutral-600 h-full overflow-y-auto">
      {Object.keys(KEY_LABEL)
        .filter((key) => !FILTER_KEYS.includes(key))
        .map((key) => (
          <RenderKey
            key={key}
            label={KEY_LABEL[key]}
            value={article.extraction[key]}
          />
        ))}
    </div>
  );
};
const Button = ({ children, disabled, onClick, loading }) => {
  return (
    <button
      className={`text-sm px-2 py-1 rounded-md bg-neutral-100 text-neutral-600 transition hover:bg-neutral-200 active:bg-neutral-300 flex items-center gap-2 ${
        disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
      } ${loading ? "cursor-not-allowed opacity-50" : ""}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && <Loading03Icon className="animate-spin" />}
      {children}
    </button>
  );
};

const CommentSection = ({ articleId }) => {
  const auth = useAuth();
  const [commentText, setCommentText] = React.useState("");
  const comments = useQuery(
    ["comments", articleId],
    () => DocService.getComments(articleId),
    {}
  );

  const saveComment = useMutation(
    (data) =>
      DocService.addComment(articleId, {
        ...data,
        pubmed_id: articleId,
        comment_by: auth.user.username,
      }),
    {
      onSuccess: () => {
        comments.refetch();
        setCommentText("");
      },
    }
  );
  return (
    <div className="relative h-full overflow-hidden">
      <div className="overflow-y-auto h-full">
        <div className="flex flex-col gap-2  pb-32">
          {comments.data?.results.map((comment, idx) => (
            <div
              key={comment.id}
              className={`flex flex-col gap-1 p-2 border-b ${
                idx === comments.data?.results.length - 1
                  ? "border-b-0"
                  : "border-b"
              }`}
            >
              <div className="text-sm text-neutral-600">{comment.comment}</div>
              <div className="flex gap-3 text-neutral-400">
                <div className="text-xs">
                  {moment(comment.created_at).format("MMM DD, YYYY")}
                </div>
                <div className="text-xs capitalize">{comment.comment_by}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="absolute bottom-0 left-0 right-0 bg-white">
        <Input.TextArea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          className="mb-2 mt-4"
        />
        <Button
          onClick={() => saveComment.mutate({ comment: commentText })}
          loading={saveComment.isLoading}
        >
          <span className="px-4 py-1 "> Save</span>
        </Button>
      </div>
    </div>
  );
};

const ArticleView = ({ article, fullView = false }) => {
  const [bookMarkFlag, setBookMarkFlag] = React.useState(false);
  const [pdfImportFlag, setPdfImportFlag] = React.useState(false);
  const [folderFlag, setFolderFlag] = React.useState(false);
  const [pdfViewFlag, setPdfViewFlag] = React.useState(false);
  const queryClient = useQueryClient();

  const articleDetail = useQuery(["articleDetail", article.id], () => {
    return DocService.get(article.id);
  });

  const bookmarkMutation = useMutation((bookmarkId) => {
    return BookmarkService.addItem({
      bookmark_id: bookmarkId,
      item_id: article.id,
    });
  });

  const openPDFViewer = () => {
    setPdfViewFlag(true);
  };

  const onAddToBookmark = async (bookmarkId) => {
    message.loading("Adding to bookmark");
    await bookmarkMutation.mutateAsync(bookmarkId);
    message.destroy();
    message.success("Added to bookmark");
    queryClient.invalidateQueries(["bookmarkItems"]);
    setBookMarkFlag(false);
  };
  return (
    <>
      {pdfViewFlag && (
        <PDFDocView
          docId={article.pubmed_id}
          pmid={article.pubmed_id}
          visible={pdfViewFlag}
          onClose={() => {
            setPdfViewFlag(false);
          }}
        />
      )}
      {pdfImportFlag && (
        <UploadPDF
          itemId={article.id}
          visible={pdfImportFlag}
          onCancel={() => setPdfImportFlag(false)}
          onConfirm={() => {
            setPdfImportFlag(false);
            queryClient.invalidateQueries(["pubmedItem", article.id]);
          }}
        />
      )}
      {bookMarkFlag && (
        <BookMarkModal
          visible={bookMarkFlag}
          setVisible={setBookMarkFlag}
          setSelectedBookmark={onAddToBookmark}
        />
      )}
      <div className="p-4 flex flex-col gap-2 h-full">
        <div
          className={`flex gap-2 flex-wrap mt-6 ${fullView ? "mt-0" : "mt-6"}`}
        >
          {article.extraction.relevance ? (
            <span className="text-sm text-green-500 bg-green-50 border border-green-300 px-2 py-0.5 rounded-lg">
              Include
            </span>
          ) : (
            <span className="text-sm text-red-500 bg-red-50 border border-red-300 px-2 py-0.5 rounded-lg">
              Exclude
            </span>
          )}
          {article.nccn_tissue_type && (
            <span className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <DnaIcon className="w-4 h-4" />
              {article.nccn_tissue_type}
            </span>
          )}
          {article.drug && (
            <span className="text-sm text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
              <Medicine02Icon className="w-4 h-4" />
              {article.drug}
            </span>
          )}
        </div>
        <h2 className="text-xl font-semibold ">{article.title}</h2>

        <div className="flex gap-2 flex-wrap">
          <span className="text-sm text-neutral-500  leading-[160%]">
            <Calendar03Icon className="w-4 h-4 text-neutral-500 inline mr-2" />
            {article.published_date}
            {article.authors?.length > 0 && (
              <>
                <UserMultiple02Icon className="w-4 h-4 text-neutral-500  inline mx-2 ml-4" />
                {article.authors?.join(", ")}
              </>
            )}
          </span>
        </div>
        <div className="flex gap-2 mt-2 flex-wrap">
          {article.pubmed_id && (
            <Button
              onClick={() => {
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${article.pubmed_id}`,
                  "_blank"
                );
              }}
            >
              <Link04Icon className="w-4 h-4" />
              PubMed
            </Button>
          )}
          {article.doi && (
            <Button
              onClick={() => {
                if (article.doi.startsWith("http")) {
                  window.open(article.doi, "_blank");
                } else {
                  window.open(`https://doi.org/${article.doi}`, "_blank");
                }
              }}
            >
              <News01Icon className="w-4 h-4" />
              DOI
            </Button>
          )}

          <Button
            onClick={() => {
              window.open(article.full_text_url, "_blank");
            }}
            disabled={!article.full_text_url}
          >
            <LicenseIcon className="w-4 h-4" />
            Full Text
          </Button>
          <Button
            onClick={() => setPdfViewFlag(true)}
            disabled={!article.has_pdf}
          >
            <Pdf01Icon className="w-4 h-4" />
            View PDF
          </Button>
          <Button onClick={() => setPdfImportFlag(true)}>
            <Upload02Icon className="w-4 h-4" />
            Upload PDF
          </Button>
          {fullView && (
            <Button
              onClick={() => {
                window.open(`/v2/article/${article.id}`, "_blank");
              }}
            >
              <LinkSquare01Icon className="w-4 h-4" />
              Open in New Tab
            </Button>
          )}

          <Button onClick={() => setBookMarkFlag(true)}>
            <Bookmark01Icon className="w-4 h-4" />
            Bookmark
          </Button>

          <Button onClick={() => setFolderFlag(true)}>
            <Folder01Icon className="w-4 h-4" />
            Folder
          </Button>
          <Button onClick={() => {}}>
            <Edit02Icon className="w-4 h-4" />
            Suggest Changes
          </Button>
        </div>
        <div className="flex-1 overflow-hidden">
          <Tabs defaultActiveKey="1" className="h-full">
            <Tabs.TabPane tab="Summary" key="1" className="text-neutral-600">
              {SUMMARY_KEY.map((key) => (
                <div key={key} className="my-2">
                  <div className="text-sm font-semibold ">
                    {SUMMARY_KEY_LABEL[key]}
                  </div>
                  <div>{article.summary[key]}</div>
                </div>
              ))}
            </Tabs.TabPane>
            <Tabs.TabPane tab="Abstract" key="2">
              <div className="text-sm text-neutral-600">
                {article.abstract}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Extracted Details"
              key="3"
              className="h-full overflow-y-auto"
            >
              {/* <div className="text-base text-neutral-600 h-full overflow-y-auto">
                {Object.keys(KEY_LABEL)
                  .filter((key) => !FILTER_KEYS.includes(key))
                  .map((key) => (
                    <RenderKey
                      key={key}
                      label={KEY_LABEL[key]}
                      value={article.extraction[key]}
                    />
                  ))}
              </div> */}
              <ExtractedDetails article={article} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Comments" key="4" className="h-full">
              <CommentSection articleId={article.id} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="LLM Questions"
              key="5"
              className="h-full overflow-y-auto"
            >
              <div className="text-base text-neutral-500">
                <div>
                  <div className="text-sm font-semibold mt-2 mb-2">Cancer Types</div>
                  <ReactJson src={articleDetail.data?.cancer_types_data} />
                </div>
                <div>
                  <div className="text-sm font-semibold mt-8 mb-2">
                    Relevance Data
                  </div>
                  <ReactJson src={articleDetail.data?.relevance_data} />
                </div>
                <div>
                  <div className="text-sm font-semibold mt-8 mb-2">
                    Advanced Extraction Data
                  </div>
                  <ReactJson
                    src={articleDetail.data?.advanced_extraction_data || {}}
                  />
                </div>
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default ArticleView;
