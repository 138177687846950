import React from "react";
import { Col, Divider, Row, Typography } from "antd";

const COLUMN_MAP = {
  authorlist: "Authors",
  benign_tumor_found: "Benign tumor found?",
  journal: "Journal",
  combination_or_alone_option2_abstract: "Combination or Alone",
  pmid: "PMID",
  oncotree_primary_tissue: "Primary tissue type",
  outcomefilter_terms: "Relevant outcomes",
  predicted_rctclass: "Predicted RCT",
  predicted_taclass: "Predicted therapeutic association",
  publisheddate: "Published date",
  rel_predictions: "Relavant predictions",

  parsed_pubtator_drug: "Pubtator drug",
  queryrundate: "Query run date",
};

const DataRow = ({ item, dataKey }) => {
  return (
    <div
      style={{
        maxWidth: "700px",
      }}
    >
      <Row key={dataKey} justify="space-between">
        <Col span={12}>
          <Typography.Text type="secondary">
            {COLUMN_MAP[dataKey]}
          </Typography.Text>
        </Col>

        <Col span={12}>
          {" "}
          <span>{item[dataKey]}</span>
        </Col>
      </Row>
      <Divider
        style={{
          margin: "6px 0",
        }}
      />
    </div>
  );
};

const PipelineDataView = ({ data }) => {
  //   data.authorlist = data.authorlist
  //     .replace("'", "")
  //     .replace('"', "")
  //     .replace("]", "")
  //     .replace("[", "");
  // Regex sub
  data.authorlist = data.authorlist?.replace(/['"[\]]/g, "");
  console.log(data.pubtator_drug);
  // Pubtator drug
  data.parsed_pubtator_drug = data.pubtator_drug
    ? JSON.parse(
        data.pubtator_drug
          .replace(/\(/g, "[") // Replace '(' with '['
          .replace(/\)/g, "]") // Replace ')' with ']'
          .replace(/'/g, '"')
          .replace(/None/g, "null")
      ) || []
    : [];
  data.parsed_pubtator_drug =
    data.parsed_pubtator_drug
      ?.map((drug) => {
        return `${drug[0]} (${drug[1]})`;
      })
      .join(", ") || "N/A";
  return (
    <div>
      {data &&
        data != {} &&
        Object.keys(COLUMN_MAP).map((key) => {
          return <DataRow item={data} key={key} dataKey={key} />;
        })}

      {(!data || data == {}) && <div>No data found</div>}
    </div>
  );
};

export default PipelineDataView;
