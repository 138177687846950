import React from "react";
import { Col, Divider, Row, Tag, Typography } from "antd";

const COLUMN_MAP = {
  combination: "Combination",
  drugs: "Drugs",
  patient_num: "Patient num",
  relevance: "Relevance",
  relevance_explanation: "Relevance explanation",
  study_type: "Study type",
  ta: "Therapeutic association",
  ta_explanation: "Therapeutic association explanation",
  tissue: "Tissue type",
//   result_stats: "Result Stats",
//   reference_sent: "Reference Sentence",
};
const OUTCOME_MAP = {
  result_stats: "Result Stats",
  reference_sent: "Reference Sentence",
};
const DataRow = ({ item, dataKey }) => {
  return (
    <div
      style={{
        maxWidth: "700px",
      }}
    >
      <Row key={dataKey} justify="space-between">
        <Col span={12}>
          <Typography.Text type="secondary">
            {COLUMN_MAP[dataKey] || OUTCOME_MAP[dataKey]}
          </Typography.Text>
        </Col>

        <Col span={12}>
          {" "}
          <span>{item[dataKey]}</span>
        </Col>
      </Row>
      <Divider
        style={{
          margin: "6px 0",
        }}
      />
    </div>
  );
};

const EndpointDetails = ({ data }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Typography.Title
          level={5}
          style={{
            margin: 0,
          }}
        >
          {data.outcome_name}
        </Typography.Title>
        <div>
          {data.impact == "Positive" ? (
            <Tag color="green">Positive</Tag>
          ) : data.impact == "Negative" ? (
            <Tag color="red">Negative</Tag>
          ) : (
            <Tag color="gray">Inconclusive</Tag>
          )}
        </div>
      </div>
      {data &&
        Object.keys(OUTCOME_MAP).map((key) => {
          return <DataRow item={data} key={key} dataKey={key} />;
        })}
    </div>
  );
};

const OpenAIDataView = ({ data }) => {
  //   data.authorlist = data.authorlist
  //     .replace("'", "")
  //     .replace('"', "")
  //     .replace("]", "")
  //     .replace("[", "");
  // Regex sub
  //   data.authorlist = data.authorlist.replace(/['"\[\]]/g, "");
  //   console.log(data.pubtator_drug);
  //   // Pubtator drug
  //   data.parsed_pubtator_drug = JSON.parse(
  //     data.pubtator_drug
  //       .replace(/\(/g, "[") // Replace '(' with '['
  //       .replace(/\)/g, "]") // Replace ')' with ']'
  //       .replace(/'/g, '"')
  //       .replace(/None/g, "null")
  //   );
  //   data.parsed_pubtator_drug = data.parsed_pubtator_drug.map((drug) => {
  //     return `${drug[0]} (${drug[1]})`;
  //   }).join(", ");
  return (
    <div>
      {data &&
        Object.keys(COLUMN_MAP).map((key) => {
          return <DataRow item={data} key={key} dataKey={key} />;
        })}
        <Typography.Title level={4}>Endpoint Outcomes</Typography.Title>
      {data.endpoint_outcomes?.map((outcome) => {
        return <EndpointDetails key={outcome.outcome_name} data={outcome} />;
      })}
    </div>
  );
};

export default OpenAIDataView;
