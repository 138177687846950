import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Radio,
  Select,
  Space,
  message,
  Divider,
  Typography,
  Popover,
  Tabs,
  Modal,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  CommentOutlined,
  BookOutlined,
  LinkOutlined,
  CalendarOutlined, 
  UserOutlined
} from "@ant-design/icons";
import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  BookmarkService,
  PubmedConfig,
  PubmedDataItems,
} from "../../services/dataService";
import ReactJson from "react-json-view";
import { useAuth } from "../../authContext";
import UploadPDF from "./importPDF";
import PDFDocumentView from "../../components/pdfViewer";
import PDFDocView from "./DocView";
import InlineCommentHighlight from "./inlineComment";
import CommentSection from "./comments";

const { Option } = Select;

const RenderValue = ({ fieldPath, feedback, value, onFeedback }) => {
  if (feedback) {
    // console.log("feedback->", feedback, fieldPath);
  }
  const [comment, setComment] = useState(feedback?.comment);
  const handleFeedback = (value) => {
    let val = value == "correct" ? { is_correct: true } : { is_correct: false };
    onFeedback(fieldPath, { ...feedback, ...val });
  };

  const handleComment = (value) => {
    onFeedback(fieldPath, { ...feedback, comment: value });
  };

  const labelContent = () => {
    return (
      <div style={{ minWidth: "300px", minHeight: "100px" }}>
        <Space direction="vertical" style={{ width: "100%" }} align="left">
          <Button type="link" onClick={() => handleFeedback("correct")}>
            {" "}
            <CheckOutlined /> Correct
          </Button>
          <Button
            type="link"
            danger
            onClick={() => handleFeedback("incorrect")}
          >
            <CloseOutlined /> Incorrect
          </Button>
          <Input.TextArea
            placeholder="Add comment"
            autoSize={{ minRows: 2, maxRows: 6 }}
            style={{ width: "100%" }}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            value={comment}
          />
          <Button
            type="link"
            onClick={() => {
              handleComment(comment);
            }}
          >
            <CommentOutlined />
            Add Comment
          </Button>
        </Space>
      </div>
    );
  };
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((item, index) => {
          return (
            <RenderValue
              key={index}
              value={item}
              fieldPath={[...fieldPath, index]}
              onFeedback={onFeedback}
              feedback={feedback[index] || {}}
            />
          );
        })}
      </>
    );
  } else if (typeof value == "object") {
    return <ReactJson src={value} />;
  } else {
    return (
      <Row
        style={{
          width: "100%",
          marginLeft: "1rem",
        }}
      >
        <Popover content={labelContent}>
          <Typography.Text>{value}</Typography.Text>{" "}
          {feedback.is_correct == true ? (
            <CheckOutlined style={{ color: "mediumseagreen" }} />
          ) : null}
          {feedback.is_correct == false ? (
            <CloseOutlined style={{ color: "red" }} />
          ) : null}
          {feedback.comment ? (
            <CommentOutlined style={{ color: "dodgerblue" }} />
          ) : null}
        </Popover>
      </Row>
    );
  }
};

const NestedField = ({
  fieldPath,
  field,
  feedback,
  mapping,
  value,
  onFeedback,
}) => {
  let properties = mapping.properties;

  //   const onChangeNested = (key, modValue) => {
  //     onChange({
  //       ...value,
  //       properties: { ...properties, [key]: { ...modValue } },
  //     });
  //   };

  let isArray = Array.isArray(value);
  let values = value;

  //   console.log("Feedback", feedback, fieldPath, field);
  return (
    <>
      {isArray && properties ? (
        <>
          <>
            <Row align="bottom" style={{ padding: "0.5rem" }}>
              <Col span={8}>
                <Typography.Text level={5} type="secondary">
                  {mapping.label || field}
                </Typography.Text>
              </Col>
              {values.map((value, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "1rem",
                      margin: "0.5rem",
                      border: "1px solid #80808066",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography.Text type="secondary">
                      {mapping.label || field} #{index + 1}
                    </Typography.Text>
                    <div style={{ marginLeft: "1rem" }}>
                      {Object.keys(properties).map((key) => {
                        return (
                          <NestedField
                            key={key}
                            field={key}
                            feedback={
                              feedback[index] ? feedback[index][key] || {} : {}
                            }
                            fieldPath={[...fieldPath, field, index]}
                            mapping={properties[key]}
                            value={value[key]}
                            // onChange={(value) => onChangeNested(key, value)}
                            onFeedback={onFeedback}
                          ></NestedField>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Row>
          </>
        </>
      ) : (
        <>
          {properties ? (
            <>
              <Row style={{ padding: "0.5rem" }} align="bottom">
                <Col span={8}>
                  <Typography.Title
                    level={5}
                    style={{
                      margin: "0px",
                    }}
                  >
                    {mapping.label || field}
                  </Typography.Title>
                </Col>
                <Col span={16}></Col>
              </Row>
              <div style={{ marginLeft: "1rem" }}>
                {Object.keys(properties).map((key) => {
                  return (
                    <NestedField
                      key={key}
                      field={key}
                      feedback={feedback[key] || {}}
                      fieldPath={[...fieldPath, field]}
                      mapping={properties[key]}
                      value={value[key]}
                      //   onChange={(value) => onChangeNested(key, value)}
                      onFeedback={onFeedback}
                    ></NestedField>
                  );
                })}
              </div>
            </>
          ) : null}
          {!properties ? (
            <Row
              style={{ padding: "0.5rem", borderBottom: "1px solid #80808066" }}
            >
              <Col span={8}>
                <Typography.Text type="secondary">
                  {mapping.label || field}
                </Typography.Text>
              </Col>
              <Col span={16}>
                {/* <Typography.Text>{value}</Typography.Text>
                 */}
                <RenderValue
                  fieldPath={[...fieldPath, field]}
                  feedback={feedback || {}}
                  onFeedback={onFeedback}
                  value={value}
                />
              </Col>
            </Row>
          ) : null}
        </>
      )}
    </>
  );
};

const DetailView = (props) => {
  //   const [feedback, setFeedback] = useState({});
  const config = useQuery(["config"], () => PubmedConfig.getConfig(), {
    onSuccess: (data) => {
      //   setState({
      //     ...state,
      //     mappings:
      //       data?.["test-pubmed-data"]?.mappings ||
      //       data?.["pubmed-data"]?.mappings,
      //   });
    },
    staleTime: Infinity,
  });

  let mappings =
    config.data?.["test-pubmed-data"]?.mappings ||
    config.data?.["pubmed-data"]?.mappings;
  let relMappings = mappings?.properties[[props.dataKey]];
  //   console.log("properties", properties);

  let addFeedbackRecursively = (feedback, fieldPath, value) => {
    // Check if key is for array by checking if key is int
    let key = fieldPath[0];
    let isKeyForArr = !isNaN(key);

    let newFeedback;
    // if key is for array, then we need to add a new objects to the current index
    if (isKeyForArr) {
      if (!Array.isArray(feedback)) {
        feedback = [];
      }
      let index = key - 1;
      // Check if array has enough elements to add new object
      let numElementsToAdd = index - feedback.length + 1;
      if (numElementsToAdd > 0) {
        for (let j = 0; j < numElementsToAdd; j++) {
          feedback.push({});
        }
      }

      newFeedback = feedback;
      newFeedback[key] = { ...newFeedback[key] };
    } else {
      newFeedback = { ...feedback };
    }

    if (fieldPath.length > 1) {
      newFeedback[fieldPath[0]] = addFeedbackRecursively(
        feedback[fieldPath[0]] || {},
        fieldPath.slice(1),
        value
      );
    } else {
      newFeedback[fieldPath[0]] = value;
    }
    return newFeedback;
  };

  let addFeedback = (fieldPath, value) => {
    let existingFeedback = { ...props.feedback };
    let newFeedback = addFeedbackRecursively(
      existingFeedback,
      fieldPath,
      value
    );
    // debugger;
    props.onUpdateFeedback(newFeedback);
  };

  return (
    <div>
      {relMappings ? (
        <>
          <NestedField
            field={props.dataKey}
            fieldPath={[]}
            mapping={relMappings}
            value={props.data}
            feedback={props.feedback[props.dataKey] || {}}
            onFeedback={(fieldPath, value) => {
              addFeedback(fieldPath, value);
            }}
          ></NestedField>
        </>
      ) : null}
    </div>
  );
};

const PubDetailView = ({ itemId, pubmedId, selected }) => {
  const auth = useAuth();

  const [feedback, setFeedback] = useState({});
  const [newBookmarkName, setNewBookmarkName] = useState("");
  const [pdfUploadFlag, setPdfUploadFlag] = useState(false);
  const queryClient = useQueryClient();

  const [pdfFlag, setPdfFlag] = useState(false);
  const [pdfAvailable, setPdfAvailable] = useState(false);

  const updateFeedback = useMutation(
    (feedback) => PubmedDataItems.addFeedback(selected?.id, feedback),
    {
      onSuccess: (data) => {
        message.success("Feedback updated successfully");
      },
      onError: (error) => {
        message.error("Error updating feedback");
      },
    }
  );
  console.log("selected?.i", selected);
  const pubmedItem = useQuery(
    ["pubmedItem", selected?.id],
    () => PubmedDataItems.get(selected?.id),
    {
      onSuccess: (data) => {
        setFeedback(data?.feedback || {});
      },
      staleTime: Infinity,
      enabled: selected?.id ? true : false,
    }
  );
  const handleAddFeedback = (value) => {
    let existingFeedback = { ...feedback, ...value };
    setFeedback(existingFeedback);
    updateFeedback.mutate(existingFeedback);
  };

  const otherDetails = useQuery(
    ["otherDetails", selected?.id],
    () => PubmedDataItems.otherDetails(selected?.id),
    {
      staleTime: Infinity,
      enabled: selected?.id ? true : false,
    }
  );
  const bookmarkList = useQuery(["bookmarks"], () => BookmarkService.getAll(), {
    staleTime: Infinity,
  });

  const bookmarkItem = useMutation(
    (bookmarkId) =>
      BookmarkService.addItem({
        bookmark_id: bookmarkId,
        // pubmed_id: selected?.pubmed_id,
        item_id: selected?.id,
      }),
    {
      onSuccess: (data) => {
        message.success("Item added to bookmark");
        queryClient.invalidateQueries(["bookmarkItems"]);
      },
      onError: (error) => {
        message.error("Error adding item to bookmark");
      },
    }
  );
  const createNewBookmark = useMutation(
    (name) => BookmarkService.create({ owner_id: auth.user.id, name: name }),
    {
      onSuccess: (data) => {
        message.success("Bookmark created successfully");
        // bookmarkList.refetch();
        queryClient.invalidateQueries(["bookmarks"]);
      },
      onError: (error) => {
        message.error("Error creating bookmark");
      },
    }
  );

  const pdfBlob = useQuery(
    ["pdfBlob", selected?.id],
    () => PubmedDataItems.downloadPdf(selected?.id),
    {
      onSuccess: (data) => {
        setPdfAvailable(true);
      },
      onError: (error) => {
        //   message.error("Error downloading PDF");
        setPdfAvailable(false);
      },
      retry: false,
    }
  );

  return (
    <>
      {
        // <Modal
        //   open={fileBlob ? true : false}
        //   onCancel={() => setFileBlob(null)}
        //   width={800}
        // >
        //   {/* <Button onClick={() => setFileBlob(null)}>Close</Button>
        //   <br />
        //   <br /> */}
        //   <div style={{ height: "80vh" }}>
        //     <PDFDocumentView
        //       blob={fileBlob}
        //       title={
        //         selected?.pubmed_id
        //           ? `${selected?.pubmed_id}.pdf`
        //           : `${selected?.title}.pdf`
        //       }
        //       name={auth.user.username}
        //       email={auth.user.email}
        //     />
        //   </div>
        // </Modal>

        selected?.id && pubmedItem.data?.has_pdf && (
          <PDFDocView
            docId={selected?.id}
            pmid={selected?.pubmed_id}
            visible={pdfFlag}
            onClose={() => {
              setPdfFlag(false);
            }}
          />
        )
      }
      {selected ? (
        <>
          <UploadPDF
            itemId={selected?.id}
            visible={pdfUploadFlag}
            onCancel={() => setPdfUploadFlag(false)}
            onConfirm={() => {
              setPdfUploadFlag(false);
            }}
          />
          <Typography.Title
            level={3}
            style={{
              marginBottom: "0.125rem",
            }}
          >
            {selected.title}
          </Typography.Title>
          <Row>
          <Typography.Text type="secondary">
          <CalendarOutlined /> {otherDetails?.data?.publication_date}
            </Typography.Text>
            <Typography.Text type="secondary">
            <UserOutlined  style={{
              marginLeft: "1rem",
            }}/> {selected.authors?.join(", ")}
            </Typography.Text>
          </Row>
          <Space align="center">
            <Button
              type="link"
              onClick={() =>
                window.open(
                  `https://pubmed.ncbi.nlm.nih.gov/${pubmedItem.data?.pubmed_id}/`,
                  "_blank"
                )
              }
            >
              <LinkOutlined /> Pubmed
            </Button>
            <Button
              type="link"
              onClick={() =>
                window.open(otherDetails?.data?.full_text_url, "_blank")
              }
              disabled={!otherDetails?.data?.full_text_url}
            >
              <LinkOutlined /> Full Text
            </Button>

            <Popover
              trigger="click"
              content={
                <div style={{ width: "250px" }}>
                  <Space
                    direction="vertical"
                    split={<Divider style={{ margin: "0px" }} />}
                  >
                    {bookmarkList.data?.results?.map((bookmark) => (
                      <Button
                        key={bookmark.id}
                        type="link"
                        onClick={() => {
                          bookmarkItem.mutate(bookmark.id);
                        }}
                      >
                        {bookmark.name}
                      </Button>
                    ))}
                    <Space.Compact
                      style={{ width: "100%", padding: "0 0.5rem" }}
                    >
                      <Input
                        size="small"
                        value={newBookmarkName}
                        onChange={(e) => {
                          setNewBookmarkName(e.target.value);
                        }}
                      />
                      <Button
                        onClick={() => {
                          createNewBookmark.mutate(newBookmarkName);
                        }}
                        size="small"
                        loading={createNewBookmark.isLoading}
                      >
                        Create New
                      </Button>
                    </Space.Compact>
                  </Space>
                </div>
              }
            >
              <Button
                type="link"
                size="small"
                style={{
                  marginLeft: "0px",
                  paddingLeft: "0px",
                  // marginBottom: "0.5rem",
                }}
              >
                Bookmark <BookOutlined />
              </Button>
            </Popover>
            <Button
              type="link"
              size="small"
              style={{ marginLeft: "0px" }}
              onClick={() => {
                setPdfUploadFlag(true);
              }}
            >
              Upload PDF
            </Button>
            <Button
              type="link"
              size="small"
              disabled={!pubmedItem.data?.has_pdf}
              style={{ marginLeft: "0px" }}
              onClick={() => {
                // downloadPDF.mutate();
                setPdfFlag(true);
              }}
            >
              PDF Viewer
            </Button>
          </Space>

          <br />
          {/* <Typography.Text>{selected?.abstract}</Typography.Text> */}
          <InlineCommentHighlight abstract={selected?.abstract} />
          <Tabs>
            <Tabs.TabPane tab="Pipeline" key="1">
              <DetailView
                itemId={selected?.id}
                pmid={selected?.pubmed_id}
                data={selected?.pipeline_outputs}
                feedback={feedback}
                onUpdateFeedback={handleAddFeedback}
                dataKey={"pipeline_outputs"}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="OpenAI" key="2">
              <DetailView
                itemId={selected?.id}
                pmid={selected?.pubmed_id}
                data={selected?.gpt_data}
                feedback={feedback}
                onUpdateFeedback={handleAddFeedback}
                dataKey={"gpt_data"}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Comments" key="3">
              <CommentSection
                itemId={selected?.id}
                pubmedId={selected?.pubmed_id}
              />
            </Tabs.TabPane>
          </Tabs>
        </>
      ) : null}
    </>
  );
};

// export {UploadPDF}

export default PubDetailView;
