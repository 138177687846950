// Article PDF Viewer

import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DocService, PubmedDataItems } from "../../../services/dataService";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../authContext";
import { Spin } from "antd";
import PDFDocumentView from "../../../components/pdfViewer";
import { DnaIcon, Medicine02Icon } from "../../components/icons";

const ArticlePDFViewer = () => {
  const { articleId } = useParams();
  const auth = useAuth();

  const [fileDetails, setFileDetails] = useState({
    fileBlob: null,
    annotations: null,
    loading: false,
  });
  const article = useQuery(["articleDetail", articleId], () => {
    return DocService.get(articleId);
  });

  useEffect(() => {
    if (articleId && article.data) {
      getFile();
    }
  }, [articleId, article.data]);

  const onSave = async (blob, annotations) => {
    // debugger
    // Save the PDF
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("annotations", JSON.stringify(annotations));
    formData.append("is_open", true);

    await PubmedDataItems.uploadPdf(articleId, formData);
  };

  const getFile = async () => {
    setFileDetails({ loading: true });
    const annotations = await PubmedDataItems.getAnnotations(
      article.data.pmid || articleId
    );
    const fileData = await PubmedDataItems.downloadPdf(
      article.data.pmid || articleId
    );
    const fileBlob = new Blob([fileData], { type: "application/pdf" });
    setFileDetails({ fileBlob, annotations, loading: false });
  };

  return (
    <div className="h-full overflow-y-auto">
      {fileDetails.loading && (
        <div className="h-full flex justify-center items-center">
          <Spin />
        </div>
      )}

      {fileDetails.fileBlob && (
        <>
          <div className="flex gap-2 flex-wrap mt-2">
            {article.data.extraction.relevant ? (
              <span className="text-xs text-green-500 bg-green-50 border border-green-300 px-2 py-0.5 rounded-lg">
                Include
              </span>
            ) : (
              <span className="text-xs text-red-500 bg-red-50 border border-red-300 px-2 py-0.5 rounded-lg">
                Exclude
              </span>
            )}
            {article.data.extraction.primary_tissue_type && (
              <span className="text-xs text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
                <DnaIcon className="w-4 h-4" />
                {article.extraction.primary_tissue_type}
              </span>
            )}
            {article.drug && (
              <span className="text-xs text-neutral-700 bg-neutral-50 border border-neutral-300 px-2 py-0.5 rounded-lg flex items-center gap-1">
                <Medicine02Icon className="w-4 h-4" />
                {article.drug}
              </span>
            )}
          </div>
          <h2 className="text-xl font-semibold ">{article.data.title}</h2>

          <PDFDocumentView
            blob={fileDetails.fileBlob}
            title={`${article.data.pmid || articleId}.pdf`}
            name={auth.user.username}
            email={auth.user.email}
            onSave={onSave}
            annotations={fileDetails.annotations}
          />
        </>
      )}
    </div>
  );
};

export default ArticlePDFViewer;
