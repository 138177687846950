import React, { useState } from "react";
import { useQuery } from "react-query";
import { PipelineRunService } from "../../../services/dataService";
import moment from "moment";

const PipelineRun = () => {
  const [selectedPipelineRun, setSelectedPipelineRun] = useState(null);
  const pieplineRuns = useQuery("pipeline_run", () =>
    PipelineRunService.getAll()
  );
  return (
    <div className="flex flex-col h-full">
      <div>
        <h2 className="text-2xl font-semibold text-neutral-700">
          Pipeline Runs
        </h2>
      </div>
      <div className="flex flex-row flex-1 py-8 gap-4 h-full">
        <div className="flex max-w-[400px] flex-col gap-4 basis-1/2">
          {pieplineRuns.data?.map((pipelineRun) => (
            <div
              key={pipelineRun._id}
              className="border border-neutral-200 rounded-md p-4 cursor-pointer"
              onClick={() => setSelectedPipelineRun(pipelineRun)}
            >
              <div className="text-sm text-neutral-800">
                {pipelineRun.import_date}
              </div>
              <div className="flex items-center gap-2  mt-2">
                <div
                  className="text-xs text-neutral-500"
                  style={{
                    color:
                      pipelineRun.status == "success"
                        ? "#008000"
                        : pipelineRun.status == "pending"
                          ? "#FFA500"
                          : "#FF0000",
                  }}
                >
                  {pipelineRun.status == "completed"
                    ? "Completed"
                    : pipelineRun.status == "pending"
                      ? "Pending"
                      : "Failed"}
                </div>
                <div className="h-4 border-l border-neutral-200"></div>
                <div className="text-xs text-neutral-500">
                  {moment(pipelineRun.created_at).fromNow()}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-1 flex-col h-full">
          {selectedPipelineRun && (
            <div className="border border-neutral-200 rounded-md p-4 h-full">
              <div className="text-sm text-neutral-800 font-semibold">
                {selectedPipelineRun.import_date}
              </div>

              <div className="flex flex-col gap-2 mt-4">
                <div className="text-sm text-neutral-600 ">
                  Number of Drugs: {selectedPipelineRun.num_drugs}
                </div>
                <div className="text-sm text-neutral-600 ">
                  Number of articles: {selectedPipelineRun.num_articles}
                </div>
                <div className="text-sm text-neutral-600 ">
                  Number of articles with errors:{" "}
                  {selectedPipelineRun.num_failed_articles}
                </div>
              </div>

              <div className="mt-4">
                <div className="text-sm text-neutral-800 font-semibold">
                  Logs
                </div>

                <div className="text-sm text-neutral-600 mt-2 flex flex-col gap-2">
                  {selectedPipelineRun.messages.map((message) => (
                    <div key={message.time} className="flex flex-row gap-2">
                      {moment(message.time).format("HH:mm:ss")} -{" "}
                      {message.message}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PipelineRun;
