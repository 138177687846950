/* eslint-disable */

import React, { Component } from "react";
import ViewSDKClient from "./viewSDKClient";
import { transformAnnotation } from "./handleAnnotations";

class SizedContainer extends Component {
  viewerConfig = {
    /* Enable commenting APIs */
    enableAnnotationAPIs: true /* Default value is false */,
    showSaveButton: true,
    showLeftHandPanel: true,
    // enableLinearization: true,
    // showAnnotationTools: false,
    /* Include existing PDF annotations and save new annotations to PDF buffer */
    includePDFAnnotations: true /* Default value is false */,
    showCommentsPanel: false,
    showToolbar: true,
    showToolsOnTextSelection: true,
    showDisabledSaveButton: true,
    // showAnnotationTools: false,
    annotationUIConfig: {
      showSaveButton: true,
      showToolbar: true,
      showCommentsPanel: true,
      showToolsOnTextSelection: true,
    },
  };
  viewSDKClient = null;
  annotations = null;
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      annotations: this.props.annotations || [],
    };
  }

  componentDidMount() {
    // this.annotations = this.props.annotations.map((item) =>
    //   transformAnnotation(item, this.props.fileId)
    // );
    if (this.props.annotations) {
      this.annotations = this.props.annotations;
    }

    this.viewSDKClient = new ViewSDKClient({
      url: this.props.url,
      ...this.viewerConfig,
    });
    console.log(this.viewSDKClient.ready());
    this.viewSDKClient.ready().then(() => {
      // if (this.props.url) {
      //   var fileName = this.props.fileName;
      //   var request = new XMLHttpRequest();
      //   request.open("GET", this.props.url, true);
      //   request.responseType = "blob";
      //   request.onload = () => this.onFileDownloadComplete(request.response);
      //   request.send();
      // } else {
      //   this.props.dataLoader().then((response) => {
      //     this.onFileDownloadComplete(response);
      //   });
      // }

      this.onFileDownloadComplete(this.props.blob);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.blob !== prevProps.blob) {
      this.viewSDKClient = new ViewSDKClient({
        url: this.props.url,
        ...this.viewerConfig,
      });
      console.log(this.viewSDKClient.ready());
      this.viewSDKClient.ready().then(() => {
        this.onFileDownloadComplete(this.props.blob);
      });
      // this.annotations = this.props.annotations.map((item) =>
      //   transformAnnotation(item, this.props.fileId)
      // );
      this.annotations = this.props.annotations;


    }

  }

  onFileDownloadComplete = (e) => {
    var reader = new FileReader();
    reader.onloadend = (e) => {
      const filePromise = Promise.resolve(e.target.result);
      /* Helper function to render the file using PDF Embed API. */
      const somePromise = this.viewSDKClient.previewFileUsingFilePromise(
        "pdf-div",
        filePromise,
        this.props.fileName,
        this.props.fileId,
        this.viewerConfig
      );
      somePromise.then((adobeViewer) => {
        adobeViewer.getAnnotationManager().then((annotationManager) => {
          /* API to add annotations to PDF and return the updated PDF buffer */
          /* These APIs will work only when includePDFAnnotations is set to true in viewerConfig */
          this.setState((prev) => ({
            ...prev,
            annotationManager: annotationManager,
            adobeViewer: adobeViewer,
          }));

          annotationManager
            .addAnnotationsInPDF(this.state.annotations)
            .then((result) => {
              var annotationDict = {};
              this.annotations.forEach((element) => {
                annotationDict[element.id] = annotationDict;
              });
              this.setState((prev) => ({
                ...prev,
                annotationDict: annotationDict,
              }));
              console.log(
                "Annotations added to PDF successfully and updated PDF buffer returned.",
                result
              );
            })
            .catch((error) => {
              console.log(error);
            });

          annotationManager.registerEventListener(
            (event) => {
              // Update the annotation list in the state

              if (event.type === "ANNOTATION_ADDED") {
                this.setState((prev) => ({
                  ...prev,
                  annotations: [...prev.annotations, event.data],
                }));
              } else if (event.type === "ANNOTATION_DELETED") {
                this.setState((prev) => ({
                  ...prev,
                  annotations: prev.annotations.filter(
                    (item) => item.id !== event.data.id
                  ),
                }));
              } else if (event.type === "ANNOTATION_UPDATED") {
                this.setState((prev) => ({
                  ...prev,
                  annotations: prev.annotations.map((item) =>
                    item.id === event.data.id ? event.data : item
                  ),
                }));
              }

              
            },
            {
              /* Pass the list of events in listenOn. */
              /* If no event is passed in listenOn, then all the annotation events will be received. */
              listenOn: [
                "ANNOTATION_ADDED",
                // "ANNOTATION_CLICKED",
                "ANNOTATION_DELETED",
                "ANNOTATION_UPDATED",
              ],
            }
          );
        });
      });
      this.viewSDKClient.registerEventsHandler(
        this.props.name,
        this.props.email,
        async (blob) => {
          return this.props.onSave(blob, this.state.annotations);
        }
      );
    };
    if (e?.data) {
      reader.readAsArrayBuffer(e.data);
    } else {
      reader.readAsArrayBuffer(e);
    }
  };

  render() {
    return <div id="pdf-div" className="sized-container-div" />;
  }
}

export default SizedContainer;
